import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginGuard} from './core/guards/login.guard';
import { LayoutAppComponentComponent } from './core/layout/layout-app-component/layout-app-component.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {
    component:LayoutAppComponentComponent,
    path: 'ChannelSalesReport',
    loadChildren: () => import('./features/channel-sales-report/channel-sales-report.module').then(m => m.ChannelSalesReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'CustomerSalesReport',
    loadChildren: () => import('./features/customer-sales-report/customer-sales-report.module').then(m => m.CustomerSalesReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'ParkourSalesReport',
    loadChildren: () => import('./features/parkour-sales-report/parkour-sales-report.module').then(m => m.ParkourSalesReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'PassengerSalesReport',
    loadChildren: () => import('./features/passenger-sales-report/passenger-sales-report.module').then(m => m.PassengerSalesReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'PaymentMethotReport',
    loadChildren: () => import('./features/payment-methot-report/payment-methot-report.module').then(m => m.PaymentMethotReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'ProviderAmountReport',
    loadChildren: () => import('./features/provider-amount-report/provider-amount-report.module').then(m => m.ProviderAmountReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'ProviderCurrencyReport',
    loadChildren: () => import('./features/provider-currency-report/provider-currency-report.module').then(m => m.ProviderCurrencyReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'ProviderSaleReport',
    loadChildren: () => import('./features/provider-sale-report/provider-sale-report.module').then(m => m.ProviderSaleReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'ProviderSegmentReport',
    loadChildren: () => import('./features/provider-segment-report/provider-segment-report.module').then(m => m.ProviderSegmentReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'SegmentNew',
    loadChildren: () => import('./features/segment-new/segment-new.module').then(m => m.SegmentNewModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'OriginDestination',
    loadChildren: () => import('./features/origin-destination/origin-destination.module').then(m => m.OriginDestinationModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'MgoCodeandPnr',
    loadChildren: () => import('./features/mgo-codeand-pnr/mgo-codeand-pnr.module').then(m => m.MgoCodeandPnrModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'AirlineSalesReport',
    loadChildren: () => import('./features/airline-sales-report/airline-sales-report.module').then(m => m.AirlineSalesReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'FailTicketsReport',
    loadChildren: () => import('./features/get-fail-tickets-report/get-fail-tickets-report.module').then(m => m.GetFailTicketsReportModule),
    canActivate: [LoginGuard]
  },
  {
    component:LayoutAppComponentComponent,
    path: 'LogStatistic',
    loadChildren: () => import('./features/log-statistic/log-statistic.module').then(m => m.LogStatisticModule),
    canActivate: [LoginGuard]
  },

  {
    path: '',
    redirectTo: 'ChannelSalesReport',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
