import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menu:string[]=[]
  activeChild: string = this.router.routerState.snapshot.url;
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.menu=[
      'ChannelSalesReport',
      'CustomerSalesReport',
      'ParkourSalesReport',
      //'PassengerSalesReport',
      'PaymentMethotReport',
      'ProviderAmountReport',
      'ProviderCurrencyReport',
      'ProviderSaleReport',
      'ProviderSegmentReport',
      'SegmentNew',
      'OriginDestination',
      'MgoCodeandPnr',
      'AirlineSalesReport',
      'FailTicketsReport',
      'LogStatistic',
    ];
    console.log('activeChild',this.activeChild)
  }

}
